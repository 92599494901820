// // export this array
// export default licenseTypes;
const licenseTypes = [
  { legacyName: 'Contest', friendlyName: 'Contest' },
  { legacyName: 'Personal Learning', friendlyName: 'Personal Learning' },
  { legacyName: 'Indie', friendlyName: 'Indie' },
  { legacyName: 'Design', friendlyName: 'Pro' },
  // { legacyName: 'Design Demo', friendlyName: 'Design Demo License' },
  { legacyName: 'Studio', friendlyName: 'Live' },
  { legacyName: 'Studio Demo', friendlyName: 'Live DEMO' },
  // { legacyName: 'Collaborator', friendlyName: 'Collaborator License' },
  { legacyName: 'Library', friendlyName: 'Library' },
  { legacyName: 'Developer', friendlyName: 'Developer' }
];

// create a function to check if the licenseType is allowed for offline use based on the licenseTypes array
export const canUseOfflineLicense = (licenseType) => {
  // create a variable to hold the allowed status
  let allowed = false;

  const minLicenseType = 'Design'; // this is the minimum licenseType for offline use

  // find the index of the minLicenseType in the legacyName key
  const minIndex = licenseTypes.findIndex(license => license.legacyName === minLicenseType);

  // create an empty array to hold the allowed licenses
  let allowedLicenses = [];

  // slice the licenseTypes array from the minIndex to the end and map to legacyName keys
  allowedLicenses = licenseTypes.slice(minIndex).map(license => license.legacyName);

  // if licenseType is in allowedLicenses
  if (allowedLicenses.includes(licenseType)) {
    allowed = true;
  }

  return allowed;
};

// create a function to check an array to see if it contains at least one of the allowed licenses from canUseOfflineLicense
export const canUseOfflineLicenseArray = (licenseTypeArray) => {
  // create a variable to hold the allowed status
  let allowed = false;

  // loop through the licenseTypeArray
  for (let i = 0; i < licenseTypeArray.length; i++) {
    // if the licenseType is allowed
    if (canUseOfflineLicense(licenseTypeArray[i])) {
      // set allowed to true
      allowed = true;
      // break out of the loop
      break;
    }
  }
  return allowed;
};

// create a function to get the friendly name from a legacy name
export const getFriendlyName = (legacyName) => {
  const license = licenseTypes.find(license => license.legacyName === legacyName);
  // if cannot find the type return the legacy name
  if (!license) {
    return legacyName;
  }
  return license ? license.friendlyName : null;
};

// export this array
export default licenseTypes;
